<script setup lang="ts">
import type { Product } from 'types/models/product'

interface Props {
  data: Product
}

const props = defineProps<Props>()

const storefrontStore = useStorefrontStore()
const { currency } = storeToRefs(storefrontStore)

const { schema } = useProductSchema({
  currency,
  product: toRef(props, 'data'),
})

const json = computed(() => JSON.stringify(schema.value))
</script>

<template>
  <div
    v-html="
      `
    <script  type=&quot;application/ld+json&quot; charset=&quot;utf-8&quot;>${json}</script>

  `
    "
  />
</template>
