<script setup lang="ts">
import type { Product } from 'types/models/product'

interface Props {
  product: Product
}

const props = defineProps<Props>()
const storyblokStore = useStoryblokStore()
const product = toRef(props, 'product')

const features = computed(() => {
  return product.value.displayFeatures.map(feature => `feature-${feature}`)
})
</script>

<template>
  <section
    v-if="features.length"
    class="product-features"
    :aria-label="$t('productFeatures')"
    data-test="product-description-features"
  >
    <ul class="product-features-list">
      <li
        v-for="(feature, index) in features"
        :key="`product-${product.sku}-feature-${index}`"
        class="feature"
        data-test="product-feature"
      >
        <div
          class="feature-icon"
          aria-hidden="true"
          :style="`background-image:url(${
            storyblokStore.getPdpFeaturesIcon(features)[index]?.image.url
          })`"
          data-test="product-feature-icon"
        />
        <p class="feature-text" data-test="product-feature-text">
          {{ $t(feature) }}
        </p>
      </li>
    </ul>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
.product-features {
  width: 100%;
}
.product-features-list {
  gap: 2rem;
  display: grid;
  .feature {
    display: grid;
    gap: 1.2rem;
    grid-template-columns: 2.2rem 1fr;
  }
  .feature-icon {
    width: 2.2rem;
    height: 2.2rem;
    display: block;
    border-radius: 50%;
    background-size: contain;
    background-position: center;
  }
  .feature-text {
    font-weight: 400;
    line-height: 2.4rem;
  }
}
</style>
