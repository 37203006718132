<script setup lang="ts">
import {
  generateAlternateLinks,
  generateCanonicalLink,
  getPrimaryMeta,
  getSEODataFromProduct,
  getSeoHeadData,
  getSeoTitle,
} from 'lib/seo'
import type { WarehouseRegion } from '~/types/models/storefront'

definePageMeta({
  context: 'product',
  isProductPage: true,
})
const { $pinia } = useNuxtApp()

async function getProductOr404(sku: string) {
  const productStore = useProductStore($pinia)
  try {
    return productStore.getProductBySku(sku)
  }
  catch (_) {
    try {
      await productStore.fetchProductForPDP(sku)
      return productStore.getProductBySku(sku)
    }
    catch (error) {
      console.error('Failed to fetch product', error)
      throw createError({ statusCode: 404, message: 'Failed to fetch product' })
    }
  }
}

const {
  public: { baseUrlClient },
} = useRuntimeConfig()
const route = useRoute()
const gtm = useGTM()
const storefrontStore = useStorefrontStore()

const { currentStorefrontCode, allMarkets } = storeToRefs(storefrontStore)
const sku = route.params.sku

if (!sku || typeof sku !== 'string')
  throw createError({ statusCode: 404, message: 'Failed to fetch product' })

const product = await getProductOr404(sku)

const seoData = getSEODataFromProduct(product)

onMounted(() => {
  window.scrollTo({ top: 0 })
})

onBeforeRouteLeave(() => {
  gtm.pushCloseProduct(route.path)
})

useSeoMeta(() => getSeoHeadData(seoData, currentStorefrontCode.value))

const notAvailableInWarehouses = computed<WarehouseRegion[]>(() => {
  const stockPerWarehouse = product.amountOfVariantsInStockPerWarehouse

  if (!stockPerWarehouse)
    return []

  return Object.entries(stockPerWarehouse)
    .filter(([_, stock]) => stock === 0)
    .map(([warehouse]) => warehouse.toUpperCase() as WarehouseRegion)
})

const seoLinks = computed(() => [
  ...generateAlternateLinks({
    allMarkets: allMarkets.value,
    fullPath: route.fullPath,
    baseUrlClient,
    excludeWarehouse: notAvailableInWarehouses.value,
  }),
  generateCanonicalLink({
    baseUrlClient,
    route,
  }),
])

useHead({
  title: () => getSeoTitle(seoData, currentStorefrontCode.value, { route }),
  meta: () => (seoData && getPrimaryMeta(seoData.title, seoData.description)) || [],
  link: seoLinks,
})
</script>

<template>
  <main id="product-page">
    <Jsonld :data="product" />

    <ProductView :product="product" />
  </main>
</template>
