<script setup lang="ts">
import { isElementType } from 'types/dom'
import type { ProductImage } from 'types/models/product'

interface Props {
  images: ProductImage[]
  name?: string
  sku: string
}

withDefaults(defineProps<Props>(), {
  name: '',
  sku: '',
})

const isZoomActive = ref(false)

function getEventElements(event: Event): {
  container: HTMLLIElement
  image: HTMLDivElement
} {
  const { target: container } = event

  if (!isElementType<HTMLLIElement>(container, 'li'))
    throw new TypeError(`Invalid target ${container}`)

  const image = container.firstChild
  if (!isElementType<HTMLDivElement>(image, 'div'))
    throw new TypeError(`Invalid image ${image}`)

  return {
    container,
    image,
  }
}

function containImage(url: string): boolean {
  if (url.includes('_M') || url.includes('_FB') || url.includes('_W'))
    return false
  return true
}

function isLazy(index: number) {
  return index > 0
}

function mouseMove(event: Event) {
  const scale = 2
  const { container, image } = getEventElements(event)
  const containerInfo = container.getBoundingClientRect()
  const posX = (event as MouseEvent).clientX - containerInfo.left
  const posY = (event as MouseEvent).clientY - containerInfo.top
  zoomAndMove(image, posX, posY, scale)
}

const onMouseMove = useThrottleFn(mouseMove, 100)

function removeListeners(element: HTMLElement): void {
  element.removeEventListener('mousemove', onMouseMove)
  element.removeEventListener('mouseleave', onMouseLeave)
}

function zoomAndMove(image: HTMLDivElement, posX: number, posY: number, scale: number): void {
  image.style.transform = `translate(-${posX * (scale - 1)}px,-${
    posY * (scale - 1)
  }px) scale(${scale})`
}

function onMouseLeave(event: Event) {
  const { container, image } = getEventElements(event)
  container.classList.toggle('zoomed')
  isZoomActive.value = false
  removeListeners(container)
  image.style.transform = `translate(0%,0%) scale(1)`
}

function onZoom(event: Event) {
  const { container, image } = getEventElements(event)
  container.classList.toggle('zoomed')
  if (container.classList.contains('zoomed')) {
    isZoomActive.value = true
    container.addEventListener('mousemove', onMouseMove)
    container.addEventListener('mouseleave', onMouseLeave)
    onMouseMove(event)
    return
  }
  isZoomActive.value = false
  removeListeners(container)
  image.style.transform = `translate(0%,0%) scale(1)`
}

function onTapZoom(event: Event) {
  const { container, image } = getEventElements(event)
  container.classList.toggle('zoomed')
  if (container.classList.contains('zoomed')) {
    isZoomActive.value = true
    return mouseMove(event)
  }
  isZoomActive.value = false
  image.style.transform = `translate(0%,0%) scale(1)`
}

const resetZoom = useThrottleFn(() => {
  if (!isZoomActive.value)
    return
  const zoomedContainer = document.querySelector('.zoomed')

  if (!isElementType<HTMLLIElement>(zoomedContainer, 'li'))
    throw new TypeError(`Invalid image container ${zoomedContainer}`)

  const zoomedImage = zoomedContainer?.firstChild
  if (!isElementType<HTMLDivElement>(zoomedImage, 'div'))
    throw new TypeError(`Invalid image ${zoomedImage}`)

  zoomedContainer.classList.remove('zoomed')
  zoomedImage.style.transform = `translate(0%,0%) scale(1)`
  isZoomActive.value = false
}, 500)

onBeforeUnmount(() => {
  if (!isZoomActive.value)
    return
  const container = document.querySelector('.zoomed')
  if (!isElementType<HTMLLIElement>(container, 'li'))
    throw new TypeError(`Invalid child element ${container}`)
  if (!container)
    return
  removeListeners(container)
  isZoomActive.value = false
})

// Keep track if user is in top of the page
const topOfThePage = ref(true)
function checkScrollPosition() {
  topOfThePage.value = window.scrollY <= 5
}
onMounted(() => (addEventListener('scroll', checkScrollPosition)))
onUnmounted(() => (removeEventListener('scroll', checkScrollPosition)))
</script>

<template>
  <section class="product-gallery" data-test="product-image-gallery">
    <!-- gallery mobile -->
    <ProductAdaptiveCarousel
      v-if="images"
      class="image-slider"
      :cards-count="images.length"
      :content-id="sku"
      :show-arrow-background="false"
      data-test="slider-product-image"
      @slide="resetZoom"
    >
      <li
        v-for="(image, index) in images"
        :key="`slides-${index}`"
        data-test="carousel-product-image"
        class="card magnifying-area"
        @click="onTapZoom"
      >
        <ImageResponsive
          provider="fastly"
          class="magnifying-image"
          :src="image.url"
          :alt="`${image.position}`"
          ratio="10:11"
          :object-fit="containImage(image.url) ? 'contain' : 'cover'"
          :lazy="isLazy(index)"
        />
      </li>
    </ProductAdaptiveCarousel>

    <!-- gallery desktop -->
    <ProductAdaptiveCarousel
      v-if="images"
      class="images-grid"
      :cards-count="images.length"
      :content-id="sku"
      :show-arrow-background="false"
      data-test="product-image-grid-gallery"
      direction="column"
      :disable-vertical-scroll="!topOfThePage"
      @slide="resetZoom"
    >
      <li
        v-for="(image, index) in images"
        :key="`slides-${index}`"
        data-test="carousel-product-image"
        class="card magnifying-area"
        @click="onZoom"
      >
        <ImageResponsive
          provider="fastly"
          class="magnifying-image"
          :src="image.url"
          :alt="`${name} ${image.position}`"
          :object-fit="containImage(image.url) ? 'contain' : 'cover'"
          :lazy="isLazy(index)"
          height="100vh"
        />
      </li>
    </ProductAdaptiveCarousel>
  </section>
</template>

<style lang="scss">
@import 'assets/scss/rules/breakpoints';
.product-gallery {
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid var(--gray-light);
}
.images-grid {
  display: none;
}
.magnifying-area {
  overflow: hidden;
}
.magnifying-image {
  top: 0%;
  left: 0%;
  pointer-events: none;
  transform-origin: top left;
  transition: transform linear 0.1s;

  @media (min-width: $tablet) {
    width: 55vw;
  }
  @media (min-width: $laptop) {
    width: 60vw;
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media (min-width: $tablet) {
  .product-gallery {
    border-bottom: none;
  }
  .image-slider {
    display: none;
  }
  .magnifying-area {
    cursor: zoom-in;
  }
  .zoomed {
    cursor: zoom-out;
  }

  .images-grid {
    display: block;
  }
}
</style>
