<script setup lang="ts">
import type { Product, ProductVariant } from 'types/models/product'
import { capitalize } from 'lib/strings'

interface Props {
  product: Product
}

const props = defineProps<Props>()

const showSizeGuide = ref(false)

const storefrontStore = useStorefrontStore()
const { currency } = storeToRefs(storefrontStore)

function getDefaultVariant(product: Product) {
  const available: ProductVariant[] = product.variants.filter(
    product => product.stockStatus !== 'no-stock',
  )
  return available[0]! || product.variants[0]!
}

// TODO: Check if we can fix this reactive issues when selected a recommend product
const state = reactive({
  defaultVariant: getDefaultVariant(props.product),
  selectedVariant:
    props.product.variants.length === 1 ? getDefaultVariant(props.product) : undefined,
  addingProduct: false,
  addedProduct: false,
  forceSelection: false,
  noSize: props.product.variants[0]!.size === '0',
  noStock: props.product.stockStatus === 'no-stock',
  category: props.product.information.ageGroup,
})

const { $url } = useNuxtApp()
const path = computed(() => {
  if (state.category === 'adult')
    return $url('adults')
  else
    return $url(state.category)
})

watch(
  () => props.product,
  (product) => {
    state.noSize = product.variants[0]!.size === '0'
    state.noStock = product.stockStatus === 'no-stock'
    state.defaultVariant = getDefaultVariant(product)
    if (product.variants.length === 1) {
      state.selectedVariant = state.defaultVariant
      return
    }
    state.selectedVariant = undefined
    state.forceSelection = false
  },
)
const discountValue = computed(
  () => props.product.badges.find(badge => badge.name === 'discount')?.value,
)

const productVariant = computed(() => state.selectedVariant || state.defaultVariant)
const { isDiscounted, originalPrice, finalPrice } = useProductPrice(currency, productVariant)

const filteredBadges = computed(() =>
  props.product.badges.filter(badge => badge.name !== 'discount'),
)

const breadcrumbs = computed(() => {
  return props.product.information
    ? `${capitalize(props.product.information.ageGroup)
        } `
        + `/`
        + ` ${
        capitalize(props.product.information.family)}`
    : ''
})

function showSizeGuideModal() {
  showSizeGuide.value = true
  if (typeof window != 'undefined' && window.document)
    document.body.style.overflow = 'hidden'
}

function closeSizeGuideModal() {
  showSizeGuide.value = false
  if (typeof window != 'undefined' && window.document)
    document.body.style.overflow = 'unset'
}
</script>

<template>
  <div class="details">
    <section class="header">
      <div class="info">
        <nuxt-link class="breadcrumbs" :to="path">
          {{ breadcrumbs }}
        </nuxt-link>
        <h1 data-test="product-title" class="name">
          {{ product.name }}
        </h1>
        <div class="price__container">
          <ClientOnly>
            <span
              v-show="isDiscounted"
              class="price price--discounted"
              data-test="product-price-discounted"
            >
              {{ $price(finalPrice, true) }}
            </span>
            <span
              class="price price--full" :class="[{ 'line-through': isDiscounted }]"
              data-test="product-price-full"
            >{{ $price(originalPrice, true) }}</span>
            <ProductDiscountChip :is-discounted="isDiscounted" :discount-value="discountValue" />
          </ClientOnly>
        </div>
        <ProductBadgeList v-if="filteredBadges" class="badges" :badges="filteredBadges" />
      </div>

      <div class="product-size-guide">
        <ButtonBasic
          class="product-size-guide__btn"
          design="no-border"
          background-color="none"
          @click="showSizeGuideModal"
        >
          {{ $t('productSizeGuide') }}
        </ButtonBasic>
      </div>
    </section>
    <CartMessages />

    <!-- size guide modal -->
    <Teleport to="body">
      <div v-show="showSizeGuide" class="size-guide-modal" @click="closeSizeGuideModal">
        <ProductSizeGuideOverlay :product="product" @click.stop @close="closeSizeGuideModal" />
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/headings';
@import 'assets/scss/typography/body';

$modalHeight: calc(100vh - var(--header-main-height));

.details {
  display: contents;
  background-color: var(--white);
  z-index: 1;
  @media (min-width: $tablet) {
    width: 100%;
    border: none;
    display: block;
    position: sticky;
    height: fit-content;
  }
}

.header {
  z-index: 2;
  display: contents;
  @media (min-width: $tablet) {
    display: block;
  }
}
.info {
  padding: 2rem 2rem;
  background-color: var(--gray-lighter);
  margin-top: -2px;
  @media (min-width: $tablet) {
    margin-top: 0;
  }
}
.breadcrumbs {
  @include body2;
  color: var(--text-secondary);
}
.name {
  @include h7;
  margin-top: 2.4rem;
}

.price {
  font-size: 2rem;

  &--discounted {
    color: var(--red);
  }
  &__container {
    display: flex;
    gap: 1.2rem;
    @media (min-width: $tablet) {
      gap: 1.6rem;
    }
  }
}

.line-through {
  text-decoration: line-through;
}

.badges {
  @include h7;
  margin-top: 0.4rem;
  gap: 0.4rem;
  align-items: center;
  flex-direction: row;
  :deep(.bullet) {
    display: block;
  }
}

.product-size-guide {
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  background-color: var(--gray-lighter);

  &__btn {
    @include body2;
    align-self: flex-end;
    padding: 0;
  }
}

.size-guide-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  flex-basis: auto;
  justify-content: flex-end;
  will-change: background-color;
  background-color: var(--overlay-in-bg);
  animation: 0.3s ease forwards;
  -webkit-backdrop-filter: blur(1.5rem);
  backdrop-filter: blur(1.5rem);
  z-index: 6000;
}
</style>
