<script setup lang="ts">
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from 'radix-vue'
import type { Product } from '~/types/models/product'

interface Props {
  product: Product
}

const props = defineProps<Props>()

const emit = defineEmits(['close'])

const storyblokStore = useStoryblokStore()
const storefrontStore = useStorefrontStore()
const { currentMarketCountryCode } = storeToRefs(storefrontStore)

const sizeGuidesProduct = computed(() => {
  if (props.product)
    return storyblokStore.getSizeGuide(props.product.information.sizeGuideBlock)

  return null
})

const tabsContent = computed(() => {
  if (sizeGuidesProduct.value)
    return sizeGuidesProduct.value.size_guide_content.filter(countryContent => Boolean(countryContent._uid))

  return null
})

function mapCountryCodeToSizeGuideTab(country: string) {
  const countryToSizeGuide: Record<string, string> = {
    JP: 'JP',
    KR: 'JP',
    UK: 'UK',
    US: 'US',
    TW: 'CN',
  }

  return countryToSizeGuide[country] || 'EU'
}

const currentTab = ref(mapCountryCodeToSizeGuideTab(currentMarketCountryCode.value))

function onTabChange(selectedTab: string) {
  currentTab.value = selectedTab
}

const firstTabsContent = computed(() => tabsContent.value && tabsContent.value[0])
const firstTabsContentNotes = useRichtext(computed(() => firstTabsContent.value?.notes))

function close() {
  emit('close')
}
</script>

<template>
  <aside class="size-guide-overlay" :aria-label="$t('productSizeGuide')" data-test="size-guide-overlay">
    <div class="size-guide-overlay__header">
      <p class="size-guide-overlay__title">
        {{ $t('productSizeGuide') }}
      </p>
      <IconBasic
        filename="close" :aria-label="$t('closeSizeGuideOverlay')" data-test="size-guide-overlay-close"
        @click="close"
      />
    </div>

    <div v-if="tabsContent && tabsContent.length > 1" class="size-guide-content">
      <TabsRoot :model-value="currentTab" class="size-guide-content__container" @update:model-value="onTabChange">
        <TabsList class="size-guide-content__options" :aria-label="$t('relatedProductsSection')">
          <TabsTrigger
            v-for="sizeGuide in tabsContent" :key="sizeGuide.country" :value="sizeGuide.country"
            class="tabs-trigger"
          >
            {{ $t(sizeGuide.country) }}
          </TabsTrigger>
        </TabsList>
        <TabsContent
          v-for="sizeGuide in tabsContent" :key="sizeGuide.country" class="size-guide-content__content"
          :value="sizeGuide.country"
        >
          <div v-if="sizeGuide.notes" class="notes" v-html="useRichtext(computed(() => sizeGuide.notes)).value" />
          <ProductSizeGuideTable :size-guide="sizeGuide" />
        </TabsContent>
      </TabsRoot>
    </div>
    <div v-else-if="firstTabsContent" class="size-guide-content">
      <div v-if="firstTabsContent.notes" class="notes" v-html="firstTabsContentNotes" />
      <ProductSizeGuideTable :size-guide="firstTabsContent" />
    </div>

    <div class="size-guide-overlay__btn-container">
      <ButtonBasic
        role="button" class="close-btn" aria-label="close size guide overlay" design="simple" size="medium"
        background-color="var(--black)" data-test="size-guide-overlay-close" @click="close"
      >
        <span aria-hidden="true"> {{ $t('back') }}</span>
      </ButtonBasic>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/edges';

.size-guide-overlay {
  position: absolute;
  width: 100%;
  height: 90%;
  bottom: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: var(--white);
  padding: 2rem 2.5rem 2.4rem 3rem;

  @media (min-width: $tablet) {
    height: 100%;
    max-width: 54rem;
    margin-top: 0;
  }

  &:before {
    content: '';
    position: absolute;
    background: inherit;
    top: -2rem;
    right: 0;
    width: 100%;
    height: 2rem;
    @include scallop;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 1.6rem;

    .icon {
      width: 10px;
      height: 10px;
      position: absolute;
      top: var(--spacing-m);
      right: var(--spacing-m);
      cursor: pointer;
    }
  }

  &__title {
    @include body2;
    margin: 0 auto;
  }

  &__btn-container {
    z-index: 3;
    display: flex;
    flex-direction: column;
    margin: 5% 0 0 0;

    & button.close-btn {
      span {
        color: var(--white);
      }
    }
  }
}

.size-guide-content {
  overflow: hidden;
  height: 80vh;

  @media (min-width: $phone) {
    height: 100vh;
  }

  &__options {
    display: flex;
    justify-content: space-between;
    gap: 0.8rem;
    padding: 1.2rem 3.1rem;
    background: var(--gray-lighter);
    border-radius: 28px;
  }

  &__container {
    max-width: 100%;
    overflow: hidden;
  }

  &__content {
    width: 100%;
    height: 65vh;
    overflow-y: auto;
    scroll-behavior: smooth;

    /* Hide scrollbar for Chrome, Safari and Opera */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
        display: none;  /* Safari and Chrome */
    }

    @media (min-width: $tablet) {
      height: 75vh;
    }
    @media (min-width: $desktop) {
      height: 80vh;
    }

  }

  .tabs-trigger[data-state='active'] {
    line-height: 2.5rem;
    display: inline-block;
    background-image: url('/icons/wavyLineBlue.svg');
    position: relative;
    background-repeat: repeat-x;
    background-position-y: bottom;
  }
}

.notes {
  @include body2;
  margin-top: 2rem;
}

@media (min-width: $tablet) {
  .size-guide-overlay {
    &:before {
      width: 2rem;
      height: 100%;
      top: 0;
      left: -12px;
      --mask: radial-gradient(11.89px at calc(100% - 16.8px) 50%, #000 99%, #0000 101%) 0
          calc(50% - 16px) / 100% 32px,
        radial-gradient(11.89px at calc(100% + 8.8px) 50%, #0000 99%, #000 101%) calc(100% - 8px)
          50%/100% 32px repeat-y;
      -webkit-mask: var(--mask);
      mask: var(--mask);
      transform: rotate(180deg);
    }

    &__header {
      justify-content: space-between;
    }
  }
}
</style>
