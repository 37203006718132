<script setup lang="ts">
const cartStore = useCartStore()

function remove() {
  cartStore.state.messages = []
}

const messages = computed(() =>
  cartStore.state.messages.filter(message => message.type !== 'success'),
)

onBeforeUnmount(() => {
  cartStore.state.messages = []
})
</script>

<template>
  <ul v-if="messages && messages.length" class="cart-messages">
    <li>
      <ButtonBasic class="close" size="small" icon-left="close" @click.stop.prevent="remove" />
      <p class="message message-error">
        {{ $t('addToCartError') }}
      </p>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.cart-messages {
  padding: 1rem;
}

li {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;

  & + li {
    margin-top: 1rem;
  }
}

.message-error,
.message-notice,
.message-warning {
  color: red;
}

.close.button {
  width: 2rem;
  min-height: 1rem;
  height: 2rem;
  padding: 0;
}

.list-enter-active,
.list-leave-active {
  transition: opacity 0.5s;
}

.list-enter,
.list-leave-to {
  opacity: 0;
}
</style>
