<script setup lang="ts">
import xss from 'xss'
import type { Product } from 'types/models/product'

interface Props {
  product: Product
}

const props = defineProps<Props>()

const { $t } = useNuxtApp()
const product = toRef(props, 'product')

const storyblokStore = useStoryblokStore()
const { state: storyblok } = storeToRefs(storyblokStore)

const sustainabilityContent = useRichtext(computed(() => storyblok?.value?.pdpSustainability?.description))
const shippingContent = useRichtext(computed(() => storyblok?.value?.pdpShippingAndReturns?.description))
const productDescription = computed(() => xss(product.value.description.full))
const materials = computed(() => xss(product.value.information.composition))

const accordionSections: { title: string, description?: string, richText?: boolean, ariaLabel: string, productFeatures?: Product, extraInfo?: string }[]
  = [
    { title: 'productDescription', description: productDescription.value, ariaLabel: 'product', productFeatures: product.value, extraInfo: product.value.sku },
    { title: 'materials', description: materials.value, ariaLabel: 'materials' },
    { title: 'sustainability', description: sustainabilityContent.value, richText: true, ariaLabel: 'sustainability' },
    { title: 'shippingAndReturns', description: shippingContent.value, richText: true, ariaLabel: 'shipping and returns' },
  ]

const expandedSections = ref(new Set([0]))

function toggleAccordion(index: number) {
  expandedSections.value.has(index) ? expandedSections.value.delete(index) : expandedSections.value.add(index)
}
</script>

<template>
  <section class="product-information" :aria-label="$t('productInformationSection')">
    <section class="product-description" data-test="product-description">
      <template v-for="(section, index) in accordionSections">
        <AccordionProduct
          v-if="section.description" :key="`${section.title}-${index}`" :title="$t(`${section.title}`)"
          :expanded="expandedSections.has(index)" :data-test="`product-${section.title}-section`" class="top-divider" @toggle-accordion="toggleAccordion(index)"
        >
          <div>
            <span :aria-label="$t(`${section.title}`)" :data-test="`product-${section.title}-text`">
              <p v-if="!section.richText" class="text">
                {{ section.description }}
              </p>
              <div v-else class="text" v-html="section.description" />
            </span>
            <ProductFeatures
              v-if="section.productFeatures" class="text" :product="section.productFeatures"
              data-test="product-features"
            />
            <div v-if="section.extraInfo" class="product-code" :data-test="`product-${section.title}-extraInfo`">
              {{ `ID: ${section.extraInfo}` }}
            </div>
          </div>
        </AccordionProduct>
      </template>
    </section>
  </section>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.product-information {
  width: 100%;
  background-color: var(--gray-lighter);

  .top-divider {
    border-top: 1px solid var(--divider);
  }

  .product-description {
    padding: 1.6rem 2rem 3.4rem;
  }

  .product-code {
    width: 100%;
    font-size: 1.4rem;
    margin-bottom: 2.4rem;
    text-transform: capitalize;
    color: var(--text-secondary);
  }
}

.text {
  @include body1;
  margin: 0.8rem 0rem 2.4rem;
}

:deep(.accordion button) {
  @include body1;
}

@media (min-width: $tablet) {
  .product-description {
    padding: 1.5rem 2rem;
  }

  .text {
    @include body2;
  }

  :deep(.accordion button) {
    @include body2;
  }
}
</style>
