<script setup lang="ts">
/**
 * TODO:
 * Restructure and use AccordionBase.
 *
 * There is a AccordionBase component with barely any styling which includes the logic of an Accordion.
 * Use that component inside this and rename this component to be more product specific (due to tracking)
 */

interface Props {
  expanded?: boolean
  title: string
  content?: string
  type?: 'HTML' | 'text'
}

const props = withDefaults(defineProps<Props>(), {
  expanded: false,
  content: '',
  type: 'text',
})

const emit = defineEmits(['toggleAccordion'])
const gtm = useGTM()
const { title } = toRefs(props)

function toggle() {
  emit('toggleAccordion')

  if (props.expanded) {
    gtm.pushProductPageEvent({
      action: 'informationTabs',
      label: title.value,
    })
  }
}
</script>

<template>
  <div class="accordion" :class="{ expanded }" data-test="accordion">
    <h4 data-test="accordion-title">
      <button @click.prevent="toggle()" @keyup.stop="toggle()">
        {{ title }}
        <div class="icon">
          <span />
          <span />
        </div>
      </button>
    </h4>
    <section :aria-expanded="expanded" data-test="accordion-text">
      <div v-if="type === 'HTML'" v-html="content" />
      <p v-if="type === 'text'" v-html="content" />
      <slot />
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';
.accordion {
  display: block;
  width: 100%;

  button {
    @include body2;
    display: flex;
    justify-content: space-between;
    align-content: center;
    width: 100%;
    padding: 0.8rem 0;
    padding-right: 0.3rem;
    cursor: pointer;

    .icon {
      position: relative;
      align-self: center;
      margin-right: 0.6rem;
    }

    span {
      display: block;
      width: 1.1rem;
      height: 0.15rem;
      position: absolute;
      top: calc(50% - 0.1rem);
      left: calc(50% - 0.5rem);
      background: var(--black);
      border-radius: 0.25rem;
      transition: all ease-in-out 0.25s;

      &:last-of-type {
        transform: rotate(90deg);
      }
    }
  }

  section {
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: 400;
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height linear 0.5s;
  }

  &.expanded {
    section {
      max-height: fit-content;
    }

    button span:last-of-type {
      transform: none;
    }

    table {
      width: 100%;
    }
  }
}
@media screen and (min-width: $tablet) {
  .accordion section {
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
}
</style>

<style lang="scss">
.accordion.size-guide {
  table {
    width: 100%;
    font-size: 1.1rem;
    text-align: left;
    border: none;
  }
}
</style>
