import type { Ref } from 'vue'
import { getDisplayVariant } from 'lib/product'
import { normalizePrice } from 'lib/price'
import { url } from 'lib/url'

import type { Product } from 'types/models/product'
import type { Environment } from 'types/environment'

interface Props {
  currency: Ref<Environment.Currency>
  product: Ref<Product>
}

export function useProductSchema({ currency, product }: Props) {
  const variant = computed(() => getDisplayVariant(product.value))
  const { finalPrice } = useProductPrice(currency, variant)

  const schema = computed(() => {
    let availability
    switch (product.value.stockStatus) {
      case 'in-stock':
        availability = 'https://schema.org/InStock'
        break
      case 'low-stock':
        availability = 'https://schema.org/LimitedAvailability'
        break
      case 'no-stock':
        availability = 'https://schema.org/OutOfStock'
        break
      default:
        console.error('Unknown stockStatus:', product.value.stockStatus)
    }

    const sizes = product.value.variants.map(variant => variant.size)

    return {
      '@context': 'https://schema.org',
      '@type': 'Product',
      'name': product.value.name,
      'brand': {
        '@type': 'Organization',
        'name': 'Happy Socks',
      },
      'category': {
        '@type': 'CategoryCode',
        'name': product.value.information.family,
      },
      'sku': product.value.sku,
      'color': product.value.color.primary,
      'image': product.value.media.images.map(image => image.url),
      'itemCondition': 'https://schema.org/NewCondition',
      'size': sizes,
      'material': product.value.information.composition,
      'offers': {
        '@type': 'Offer',
        availability,
        'price': normalizePrice(finalPrice.value),
        'priceCurrency': currency.value.code,
        'sku': product.value.sku,
        'url': url(`socks/${product.value.sku}`),
      },
      'description': product.value.description.full || product.value.description.short,
    }
  })

  return {
    schema,
  }
}
