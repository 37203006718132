<script setup lang="ts">
import { isLiElementType } from 'types/dom'
import { breakpointsConfig } from 'utils/css/breakpoints'

interface Props {
  showMultipleItems?: boolean
  cardsCount?: number
  showTrack?: boolean
  contentId?: string
  showArrowBackground?: boolean
  direction?: 'row' | 'column'
  disableVerticalScroll?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showMultipleItems: false,
  cardsCount: 0,
  showTrack: true,
  contentId: '',
  showArrowBackground: true,
  direction: 'row',
  disableVerticalScroll: false,
})

const emit = defineEmits(['slide'])
const track = ref<HTMLUListElement>()
const state = reactive({
  position: 0,
  itemHeight: '100vh',
})
const mobileItemsInView = 1
const tabletItemsInView = 4
const isTablet = useMediaQuerySSR(`(min-width: ${breakpointsConfig.tablet}px)`)
const itemsInView = computed(() => isTablet.value ? tabletItemsInView : mobileItemsInView)
const showButtonLeft = computed(() => state.position > 0)
const showButtonRight = computed(() => state.position < props.cardsCount - itemsInView.value)

watch(
  () => props.contentId,
  () => {
    if (!track.value)
      return
    if (props.direction === 'row') {
      track.value.scrollTo({
        behavior: 'smooth',
        left: 0,
      })
    }
    if (props.direction === 'column') {
      track.value.scrollTo({
        behavior: 'smooth',
        top: 0,
      })
    }
  },
)

function scrollBy(nextPos: number, tap = false) {
  props.direction === 'row' ? scrollHorizontallyBy(nextPos, tap) : scrollVerticallyBy(nextPos, tap)
}

function scrollHorizontallyBy(nextPos: number, tap = false) {
  if (!track.value)
    return
  const { itemWidth } = getScrollHorizontalElementInfo()
  const nextScroll = tap ? nextPos * itemWidth : track.value.scrollLeft + nextPos * itemWidth
  track.value.scrollTo({
    behavior: 'smooth',
    left: nextScroll,
  })
}

function scrollVerticallyBy(nextPos: number, tap = false) {
  if (!track.value)
    return
  const { itemHeight } = getScrollVerticalElementInfo()
  const nextScroll = tap ? nextPos * itemHeight : track.value.scrollTop + nextPos * itemHeight
  track.value.scrollTo({
    behavior: 'smooth',
    top: nextScroll,
  })
}

function getFirstLiElement(childNodes?: NodeListOf<ChildNode>) {
  if (!childNodes)
    return undefined

  for (const el of childNodes) {
    if (isLiElementType(el))
      return el
  }

  return undefined
}

function getScrollHorizontalElementInfo(): {
  scrollPosition: number
  scrolledLeft: boolean
  scrolledRight: boolean
  canScroll: boolean
  itemWidth: number
} {
  const scrollWidth = track.value?.scrollWidth || 0
  const trackWidth = track.value?.getBoundingClientRect().width || 0
  const scrollLeft = track.value?.scrollLeft || 0
  const scrollEnd = scrollWidth - trackWidth
  const childItem = getFirstLiElement(track.value?.childNodes)
  if (!childItem)
    throw new TypeError(`Invalid child element ${childItem}`)
  const itemWidth = childItem.getBoundingClientRect().width + 1
  const sideOffset = 20
  const scrollPosition = Math.round(scrollLeft / itemWidth)
  const scrolledLeft = scrollLeft > sideOffset
  const scrolledRight = scrollLeft < scrollEnd - sideOffset
  const canScroll = scrollWidth > trackWidth

  return { scrollPosition, scrolledLeft, scrolledRight, canScroll, itemWidth }
}

function getScrollVerticalElementInfo(): {
  scrollPosition: number
  scrolledStart: boolean
  scrolledEnd: boolean
  canScroll: boolean
  itemHeight: number
} {
  const scrollHeight = track.value?.scrollHeight || 0
  const trackHeight = track.value?.getBoundingClientRect().height || 0
  const scrollStart = track.value?.scrollTop || 0
  const scrollEnd = scrollHeight - trackHeight
  const childItem = getFirstLiElement(track.value?.childNodes)
  if (!childItem)
    throw new TypeError(`Invalid child element ${childItem}`)
  const itemHeight = childItem.getBoundingClientRect().height + 1
  const sideOffset = 20
  const scrollPosition = Math.round(scrollStart / itemHeight)
  const scrolledStart = scrollStart > sideOffset
  const scrolledEnd = scrollStart < scrollEnd - sideOffset
  const canScroll = scrollHeight >= trackHeight

  return { scrollPosition, scrolledStart, scrolledEnd, canScroll, itemHeight }
}

function scrollHorizontal() {
  const { scrollPosition } = getScrollHorizontalElementInfo()
  state.position = scrollPosition
  emit('slide')
}

function scrollVertically() {
  const { scrollPosition } = getScrollVerticalElementInfo()
  state.position = scrollPosition
  emit('slide')
}

const onHorizontalScroll = useThrottleFn(scrollHorizontal, 250)
const onVerticalScroll = useThrottleFn(scrollVertically, 250)
let onMountedTimeoutHandle: ReturnType<typeof setTimeout>

onMounted(() => {
  onMountedTimeoutHandle = setTimeout(() => {
    if (props.direction === 'row') {
      const { canScroll } = getScrollHorizontalElementInfo()
      if (canScroll)
        return track.value?.addEventListener('scroll', onHorizontalScroll, { passive: true })
    }
    if (props.direction === 'column') {
      const { canScroll, itemHeight } = getScrollVerticalElementInfo()
      state.itemHeight = `${itemHeight}px`
      if (canScroll) {
        return track.value?.addEventListener('scroll', onVerticalScroll, {
          passive: true,
        })
      }
    }
  }, 1000)
})

onUnmounted(() => {
  track.value?.removeEventListener('scroll', onHorizontalScroll)
  track.value?.removeEventListener('scroll', onVerticalScroll)
  clearTimeout(onMountedTimeoutHandle)
})
</script>

<template>
  <aside class="carousel" :class="[{ 'multiple-items': showMultipleItems }]">
    <ButtonBasic
      v-if="showButtonLeft"
      data-test="carousel-button-left"
      class="slider-control left"
      :aria-label="$t('previousItem')"
      design="no-border"
      background-color="none"
      :jazzy="false"
      @click="scrollBy(-1)"
    >
      <div v-if="showArrowBackground" class="circle btn--left">
        <div class="icon" />
      </div>
    </ButtonBasic>
    <ButtonBasic
      v-if="showButtonRight"
      data-test="carousel-button-right"
      :aria-label="$t('nextItem')"
      class="slider-control right"
      design="no-border"
      background-color="none"
      :jazzy="false"
      @click="scrollBy(1)"
    >
      <div v-if="showArrowBackground" class="circle btn--right">
        <div class="icon" />
      </div>
    </ButtonBasic>

    <ul
      ref="track"
      class="track" :class="[{
        'vertical-track': direction === 'column',
        'disable-vertical-scroll': direction === 'column' && disableVerticalScroll,
      }]"
      data-test="carousel-track"
      :style="{ '--direction': direction, '--item-height': state.itemHeight }"
    >
      <slot />
    </ul>

    <ul
      v-if="cardsCount > 1 && showTrack"
      :class="[direction === 'row' ? 'pagination-track' : 'pagination-track--vertical']"
      @click.stop
    >
      <li
        v-for="(_, index) in cardsCount"
        :key="`slider-page-${index}-indicator`"
        class="page-indicator" :class="[{ 'is-active': index === state.position }]"
        @click="scrollBy(index, true)"
      />
    </ul>
  </aside>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';

$phone-large: 560px;
$tablet-small: 720px;
$desktop-mid: 1600px;
$laptop-large: 1180px;

.carousel {
  --itemsInView: v-bind(itemsInView);
}

.carousel.multiple-items {
  display: grid;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  grid-template: 'left fade-left track fade-right right'/1fr 2rem auto 2rem 1fr;
  position: relative;

  &::before,
  &::after {
    z-index: 2;
    content: '';
    width: 100%;
    height: 100%;
    position: relative;
  }
  &::before {
    left: -0.5rem;
    grid-area: fade-left;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0));
  }
  &::after {
    right: -0.5rem;
    grid-area: fade-right;
    background: linear-gradient(to left, rgba(255, 255, 255, 1) 30%, rgba(255, 255, 255, 0));
  }
}

.track {
  width: 100%;
  display: flex;
  flex-direction: var(--direction);
  overflow-x: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    display: none;
    visibility: hidden;
    background: transparent;
  }
}

.multiple-items .track {
  width: 100%;
  grid-area: track;
  max-width: calc(16rem * 3);
  background-color: var(--gray-light);
  grid-gap: 1px;
}

:slotted(.card) {
  width: 100%;
  flex-shrink: 0;
  scroll-snap-stop: always;
  scroll-snap-align: start;
  .product-card {
    border-bottom: none;
  }
  .details {
  padding: 0 1.4rem 2.4rem;
  @media (min-width: $tablet) {
    padding: 0 2.4rem 2.4rem;
  }
}
}

:slotted(.multiple-items .card) {
  width: calc(100% / var(--itemsInView));
  display: grid;
  position: relative;
  scroll-snap-stop: normal;
  background-color: var(--white);
  grid-template: '. product .'/0.5rem 1fr 0.5rem;
  .product {
    grid-area: product;
  }
}

.circle {
  width: 8rem;
  height: 8rem;
  border-radius: 4rem;
  background-color: var(--pink);
  display: flex;
  align-items: center;
}

.btn {
  &--left {
    margin-right: 10rem;
    position: sticky;

    & .icon {
      margin-left: 5rem;
      transform: rotate(180deg);
    }
  }
  &--right {
    margin-left: 10rem;
    position: sticky;

    & .icon {
      margin-left: 1rem;
    }
  }
}

.icon {
  width: 1rem;
  height: 1.5rem;
  mask: url('/icons/forward.svg') no-repeat center / contain;
  background-color: var(--black);
  opacity: 1;
}

.button.slider-control {
  z-index: 3;

  top: 50%;
  width: 3rem;
  height: 3rem;
  position: absolute;
  transform: translateY(-50%);
  &.button {
    padding: 0;
  }
  &.left {
    left: 1.3rem;
  }
  &.right {
    right: 1.3rem;
  }
}

.multiple-items .button.slider-control {
  top: unset;
  position: relative;
  transform: translateY(0);

  &.left {
    position: absolute;
    left: 0.5rem;
    grid-area: left;
    justify-self: end;
  }
  &.right {
    position: absolute;
    right: 0.5rem;
    grid-area: right;
    justify-self: start;
  }
}

.pagination-track {
  left: 50%;
  bottom: 0;
  gap: 1rem;
  z-index: 3;
  display: flex;
  padding: 2.5rem;
  position: absolute;
  transform: translate(-50%, 0%);
  pointer-events: none;

  &--vertical {
    left: 2.5%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    .page-indicator {
      margin: 0.8rem;
    }
  }
}

.page-indicator {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: var(--gray-light);
  &.is-active {
    background-color: var(--black);
  }
}

@media (min-width: $tablet-small) {
  .vertical-track {
    height: var(--item-height);
    overflow-y: auto;
    scroll-snap-type: y mandatory;
    min-height: 100vh;
  }

  .disable-vertical-scroll {
    overflow-y: hidden;
  }

  .btn {
    &--left {
      & .icon {
        margin-left: 5rem;
      }
    }
    &--right {
      & .icon {
        margin-left: 1rem;
      }
    }
  }
}

@media (min-width: $tablet) {
  .multiple-items .track {
    margin: 0;
    max-width: 100%;
  }

  .btn {
    &--left {
      & .icon {
        margin-left: 4rem;
      }
    }
    &--right {
      & .icon {
        margin-left: 2rem;
      }
    }
  }
  .carousel {
    &:hover .btn--right {
      margin-left: 7rem;
    }

    &:hover .btn--left {
      margin-right: 7rem;
    }
  }

  .carousel .icon {
    opacity: 0;
  }
  .carousel:hover .icon {
    opacity: 1;
  }
}
</style>
